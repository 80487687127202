import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/vercel/path0/src/layout/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2>{`Usage`}</h2>
    <p>{`Radius is the blunt part of the corner of a component that affects
the shape and visibility of the content inside. This is the variant
of radius we use globally for property the component. You can chek out the guideline for more detail description.`}</p>
    <div className="divi" />
    <h2>{`Preview`}</h2>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Shape`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Token Name`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Pixels`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><div className="radius1" /></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`radius1`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`2dp`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><div className="radius2" /></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`radius2`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`4dp`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><div className="radius3" /></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`radius3`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`6dp`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><div className="radius4" /></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`radius4`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`8dp`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><div className="radius5" /></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`radius5`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`12dp`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><div className="radius6" /></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`radius6`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`16dp`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><div className="radius7" /></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`radius7`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`24dp`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><div className="radius8" /></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`radius8`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`30dp`}</td>
        </tr>
      </tbody>
    </table>
    <p>{`In your composable function, can use value LegionTheme.radius.{radius_token} on the colors attribute widget. Example below:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`@Composable
fun YourComposable() {
    Box(
        modifier = Modifier
            .size(180.dp, 100.dp)
            .background(
                color = LgnPaletteTokens.Primary600,
                shape = LegionTheme.radius.radius1
            )
    )
}
`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      